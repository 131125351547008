import units from '../../utils/units'
import { BaseComponent } from '../../utils/base-component'
import './index.scss'

type Config = {
  value: number
  showUnits?: boolean
  precise?: boolean
}

const TEMP_STEPS = [-40, -35, -30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40]

function tempStep(value: number) {
    let step = TEMP_STEPS.findIndex(s => value < s)
    if (step === -1) step = TEMP_STEPS.length - 1
    return `${step + 1}`
}

export class TempValue extends BaseComponent<Config> {
  static cssClass = 'temp-value'

  target!: Element
  value?: number
  showUnits!: boolean
  precise!: boolean

  static parse(temp: string, root?: HTMLElement) {
    if (temp === '' || temp === '-') return

    const value = parseFloat(temp)
    if (Number.isNaN(value)) return

    const c = TempValue.for({ value }, root)
    c.update(value)

    return c
  }

  init() {
    const value = this.config?.value ?? parseFloat(this.root.dataset.value!)
    this.value = Number.isNaN(value) ? undefined : value
    this.showUnits = this.config?.showUnits || this.root.dataset?.units !== undefined
    this.precise = this.config?.precise || this.root.dataset.precise !== undefined
    this.toggleModifier(tempStep(this.value), true)

    this.target = this.root.tagName === 'g' ? this.root.querySelector('text')! : this.root

    this.render()
    units.onChange(() => this.render())
  }

  update(value: number) {
    const oldStep = this.value ? tempStep(this.value) : undefined
    const newStep = tempStep(value)
    if (newStep !== oldStep) {
      if (oldStep) this.toggleModifier(oldStep, false)
      this.toggleModifier(newStep, true)
    }

    this.value = value
    this.render()
  }

  render() {
    this.target.textContent = this.value === undefined
      ? '—'
      : units.formatTemp(this.value, { withUnits: this.showUnits,
                                       precision: this.precise ? 1 : 0 })
  }
}
